<script>
import {required, email, minLength, helpers} from 'vuelidate/lib/validators'
import {notificationMethods} from '@/state/helpers'
import {getApi} from '@/api/index'

// Password validation: At least 8 characters, must include letters, numbers, and special characters
const strongPassword = helpers.regex('strongPassword', /^[a-zA-Z0-9!?$%@*&^]{8,}$/)

export default {
  data() {
    return {
      user: {
        first_name: '',
        last_name: '',
        gender: '',
        country: '',
        class: '',
        email: '',
        password: ''
      },
      submitted: false,
      regError: null,
      tryingToRegister: false,
      isRegisterError: false,
      isRegisterSuccess: false,
      backgroundImages: [
        'bg-1', 'bg-3', 'bg-2', 'bg-4'
      ],
      currentImageIndex: 0,
      successMessage: ''
    }
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null
    }
  },
  validations: {
    user: {
      first_name: {required},
      last_name: {required},
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(8),
        strongPassword
      }
    }
  },
  created() {
    document.body.classList.add('auth-body-bg')
    this.changeBackgroundImage()
  },
  methods: {
    ...getApi(),
    ...notificationMethods,
    tryToRegisterIn() {
      this.submitted = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        return false;
      } else {
        this.tryingToRegister = true;
        this.regError = null;

        // API Call using fetch
        return fetch(this.apiUrl(), {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Action': 'register'
          },
          body: JSON.stringify({
            account: {
              email: this.user.email,
              password: this.user.password,
              first_name: this.user.first_name,
              last_name: this.user.last_name,
              gender: this.user.gender,
              country: this.user.country,
              class: this.user.class // User type/class
            }
          })
        })
            .then(response => response.json()) // Parse the JSON response
            .then(data => {
              this.tryingToRegister = false;
              if (data.status) {
                this.isRegisterSuccess = true;
                // Update the message to be more user-friendly
                this.successMessage = `Thank you for registering with VRsteps!
                           We've sent you a confirmation email to ${this.user.email}. Please check your inbox & spam folder. Proceed to <a href="/login">login page</a>.`;
              } else {
                this.isRegisterSuccess = false;
                this.regError = data.message || 'Registration failed. Please try again.';
                this.isRegisterError = true;
              }
            })
            .catch(error => {
              this.tryingToRegister = false;
              this.regError = error.message || 'An error occurred. Please try again later.';
              this.isRegisterError = true;
            });

      }
    },

    changeBackgroundImage() {
      setInterval(() => {
        this.currentImageIndex = (this.currentImageIndex + 1) % this.backgroundImages.length
      }, 5000) // Change image every 5 seconds
    }
  }
}
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <a href="https://www.vrsteps.io">
        <i class="mdi mdi-home-variant h2 text-white"></i>
      </a>
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-4">
            <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
              <div class="authentication-form w-100">
                <div class="d-flex">
                  <a href="https://www.vrsteps.io/" target="_blank" class="logo">
                    <img src="@/assets/images/logo-dark.png" height="48" alt="logo"/>
                  </a>
                  <div class=" text-left pl-3">
                    <h1 class="font-size-18 mt-2">Sign up</h1>
                    <h2 class="font-size-14 text-muted mb-0">Get your free VRsteps account now.</h2>
                  </div>
                </div>
                <!-- If registration is successful, show the success message -->
                <p v-if="isRegisterSuccess">
                  {{ successMessage }}
                </p>
                <!-- If registration has not been successful yet, show the form -->
                <div v-else>
                  <div class="p-2">
                    <b-alert v-model="isRegisterError" class="mt-3" variant="danger" dismissible>
                      {{ regError }}
                    </b-alert>

                    <b-alert variant="danger" class="mt-3" v-if="notification.message" show dismissible>
                      {{ notification.message }}
                    </b-alert>

                    <form class="form-horizontal" @submit.prevent="tryToRegisterIn">
                      <div class="form-group auth-form-group-custom mb-3">
                        <i class="ri-mail-line auti-custom-input-icon"></i>
                        <label for="useremail">Email</label>
                        <input
                            v-model="user.email"
                            type="email"
                            class="form-control"
                            id="useremail"
                            placeholder="Enter email"
                            required
                            :class="{ 'is-invalid': submitted && $v.user.email.$error }"
                        />
                        <div v-if="submitted && $v.user.email.$error" class="invalid-feedback">
                          <span v-if="!$v.user.email.required">Email is required.</span>
                          <span v-if="!$v.user.email.email">Please enter valid email.</span>
                        </div>
                      </div>

                      <div class="form-group auth-form-group-custom mb-3">
                        <i class="ri-lock-2-line auti-custom-input-icon"></i>
                        <label for="userpassword">Password</label>
                        <input
                            v-model="user.password"
                            type="password"
                            class="form-control"
                            id="userpassword"
                            required
                            placeholder="At least 8 alphanumeric & special chars."
                            :class="{ 'is-invalid': submitted && $v.user.password.$error }"
                        />
                        <div v-if="submitted && $v.user.password.$error" class="invalid-feedback">
                          <span v-if="!$v.user.password.required">Password is required.</span>
                          <span v-if="!$v.user.password.strongPassword">
                                Password must be at least 8 characters, alphanumeric with special characters: !?$%@*&^.
                              </span>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label for="first_name">First Name *</label>
                            <input
                                v-model="user.first_name"
                                type="text"
                                class="form-control"
                                id="first_name"
                                :class="{ 'is-invalid': submitted && !$v.user.first_name.required }"
                                placeholder="Enter first name"
                                required
                            />
                            <div v-if="submitted && !$v.user.first_name.required" class="invalid-feedback">
                              First name is required.
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-6">
                          <div class="form-group">
                            <label for="last_name">Last Name *</label>
                            <input
                                v-model="user.last_name"
                                type="text"
                                class="form-control"
                                id="last_name"
                                :class="{ 'is-invalid': submitted && !$v.user.last_name.required }"
                                placeholder="Enter last name"
                                required
                            />
                            <div v-if="submitted && !$v.user.last_name.required" class="invalid-feedback">
                              Last name is required.
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label for="gender">Gender</label>
                            <select v-model="user.gender" class="form-control" id="gender">
                              <option value="">Select gender</option>
                              <option value="1">Male</option>
                              <option value="2">Female</option>
                              <option value="3">Other</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label for="country">Country</label>
                            <select v-model="user.country" class="form-control" id="country">
                              <option value="">Select country</option>
                              <option value="IL">Israel</option>
                              <option value="US">United States</option>
                              <option value="CA">Canada</option>
                              <option value="MX">Mexico</option>
                              <option value="DE">Germany</option>
                              <option value="FR">France</option>
                              <option value="KR">South Korea</option>
                              <option value="JP">Japan</option>
                              <option value="AU">Australia</option>
                              <option value="GB">United Kingdom</option>
                              <option value="IT">Italy</option>
                              <option value="CY">Cyprus</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="class">I am a...</label>
                        <select v-model="user.class" class="form-control" id="class">
                          <option value="">Select option (optional)</option>
                          <option value="gait_hobbyist">Gait Hobbyist</option>
                          <option value="post_surgery_patient">Post-Surgery Patient</option>
                          <option value="therapist">Therapist</option>
                          <option value="doctor">Doctor</option>
                          <option value="clinic_owner">Clinic Owner</option>
                          <option value="researcher">Medical Researcher</option>
                          <option value="other">Other</option>
                        </select>
                      </div>

                      <div class="mt-2 text-left">
                        <p class="mb-2">
                          By registering you agree to the VRsteps
                          <a
                              href="https://www.vrsteps.io/terms-conditions/"
                              target="_blank"
                              class="text-primary"
                          >Terms of Use</a>
                        </p>
                      </div>

                      <div class="text-center">
                        <button class="btn btn-primary w-md waves-effect waves-light" type="submit">
                          Sign up
                        </button>
                      </div>

                    </form>
                  </div>
                  <div class="mt-1 text-center">
                    <p>
                      Already have an account?
                      <router-link tag="a" to="/login" class="font-weight-medium text-primary">Login</router-link>
                    </p>
                    <p>
                      © {{ new Date().getFullYear() }} VRsteps
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8 order-first order-first">
            <div class="authentication-bg" :class="backgroundImages[currentImageIndex]">
              <div class="bg-overlay"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
